import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import OverviewLayout from '../../containers/layout/overview-layout'
import List from '../../containers/news/list'

const ProjectList = ({ data, pageContext, location }) => (
  <OverviewLayout
    location={location}
    pageContext={pageContext}
    pageData={data.page.edges[0].node}
    subCategory={pageContext.subCategory}
  >
    <List
      items={
        pageContext.subCategory
          ? data.subList.edges.map((edge) => edge.node)
          : data.list.edges.map((edge) => edge.node)
      }
      path={pageContext.absolutePath}
      detailButtonText="Bekijk project"
    />
  </OverviewLayout>
)

export const query = graphql`
  query ProjectListQuery($skip: Int!, $limit: Int!, $category: String) {
    page: allContentfulPagina(
      filter: { id: { eq: "ffa0ccea-7349-51a0-b499-5a4e9a8aa111" } }
    ) {
      edges {
        node {
          headerTitel
          headerSubtitel
          headerButtonTekst
          headerButtonLink
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    list: allContentfulProject(
      sort: { fields: date, order: DESC }
      filter: {
        themes: { elemMatch: { title: { eq: $category } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          description {
            description
          }
          content {
            json
          }
          themes {
            id
            title
          }
          thumbnail: image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }

    subList: allContentfulProject(
      sort: { fields: date, order: DESC }
      filter: {
        subjects: { elemMatch: { title: { eq: $category } } }
        title: { ne: null }
        slug: { regex: "/^(?!untitled-entry).+/" }
        date: { ne: null }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          slug
          description {
            description
          }
          content {
            json
          }
          themes {
            id
            title
          }
          thumbnail: image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 260, cropFocus: CENTER) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

ProjectList.propTypes = {
  headingStyle: PropTypes.object,
}

export default ProjectList
